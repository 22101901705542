body {
  background-color: #fbfbff !important;
}

.emptyImage {
  width: 100%;
  max-width: 30em;
}


.filter{
  margin: 0px 5px 10px;
}


section {
  width: 100%;
  max-width: 40em;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}

.navigator {
  z-index: 2;
  width: 30px;
  height: 30px;
  align-self: center;
}

.navigator.left {
  margin-right: -30px;
}

.navigator.right {
  margin-left: -30px;
}

.carousel-control-next,
.carousel-control-prev {
  display: none;
  width: 0px;
  height: 0px;
}

.carousel-indicators {
  display: none;
}

.MuiListItem-root {
  padding: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 1px solid lightgrey;
}

.sd-element--with-frame.sd-question--title-top{
padding-top: 5px !important;
}

.page {
  height: 100%;
  min-height: 100vh;
}

.bg-white {
  padding: 10px;
  min-height: 100px;
  min-width: 200px;
}

.col {
  margin-top: 20px;
}

.main-content {
  padding-top: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDialogContent-root {
  padding: 10px !important;
}

.sd-body .sd-body__page {
  min-width: 30px !important;
}

.paper {
  padding: 15px;
  width: 100%;
  border-radius: 10px !important;
}

.header {
  margin: -10px 10px 5px;
}

.pageIcon {
  margin-left: 15px;
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.MuiTypography-root {
  font-size: 0.95rem !important;
}

.css-83ijpv-MuiTypography-root {
  font-size: 0.875rem !important;
}

.MuiDataGrid-root {
  font-size: 0.95rem !important;
}

.tab {
  margin: 12px !important;
}

@media only screen and (max-width: 577px) {
  .header {
    margin-top: 6px;
    margin-bottom: 5px;
  }

  .paper {
    width: 95vw !important;
    min-width: 95vw !important;
    margin-left: 1vw;
  }

  .MuiDialog-paper {
    margin: 5px !important;
    width: 100% !important;
  }

  .MuiDialogContent-root {
    padding: 5px !important;
  }

  .tabLabel {
    font-size: 12px !important;
  }

  .MuiBox-root {
    padding: 3px !important;
  }

  .pageIcon {
    margin-top: 8px !important;
    width: 55px;
    height: 55px;
  }
  .siteInfo {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .css-zxdg2z {
    padding: 10px !important;
  }
  .tab {
    margin-left: 0px !important;
  }
}
