
body{
    background-color: #fbfbff
}

.loginframe{
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}
.loginBackground{
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(12px);
    -webkit-filter: blur(12px);
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/backgroundimage.svg");
    background-size: cover;
    background-position: center center;
}

.newUserHeader{
    font-size: large;
    align-self: center;
}

.form-signin {
    width: 800px;
    border-radius: 10px;
    text-align:center;
    --border: rgba(0, 0, 0, 0.125);
    --borderDark: rgba(0, 0, 0, 0.25);
    --borderDarker: rgba(0, 0, 0, 0.5);
    --bgColorH: 0;
    --bgColorS: 0%;
    --bgColorL: 98%;
    --fgColorH: 210;
    --fgColorS: 50%;
    --fgColorL: 38%;
    --shadeDark: 0.3;
    --shadeLight: 0.7;
    --shadeNormal: 0.5;
    --borderRadius: 0.125rem;
    --highlight: #306090;
    background: white;
    box-shadow: 1rem 1rem 1rem -0.75rem var(--border);
    overflow: hidden;
    vertical-align: middle;
}

.loginPanel{
    width: 50%;
    padding:40px 40px 10px
}

.passwordReset {
    width:500px
}

.panelRegister{
    width:50%;
}

.LoginInput{
    position: relative;
    box-sizing: border-box;
    height: auto;
    margin-bottom:10px !important;
    margin-top:20px;
    font-size: 18px;
    display: block;
    width: 100% !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color
}

.Link{
    text-align:left;
    color: #007bff;
    text-decoration: none;
}

.btn-primary{
    width:150px;
    margin-top:20px;
}

@media only screen and (max-width: 900px) {
    .loginPanel{
        width: 100%;
        max-width: 500px;
        padding:70px 45px 30px
    }

    .panelRegister{
        width: 100%;
    }

    .form-signin {
        width: 600px;
        height: 100%;
        overflow: auto;
        border-radius: 0px;
}

}

@media only screen and (max-width: 600px) {

    .devicesImage{
        display:none;
    }
    .LoginFrame {
        grid-template-columns:auto;
    }
}

