
header{
  font-family:Helvetica, Arial, sans-serif; 
  font-weight:900;
  color:rgba(0, 0, 0, 0.75);
  font-size: 14px;
fill:rgb(55, 61, 63)}

.link{
  cursor: pointer;
  margin-bottom: -5px;
}

#smallScreen{
  display:none;
}

.carousselPaper{
  max-width: 500px;
}

@media only screen and (max-width: 900px) {
  #largeScreen{
    visibility:hidden;
  }
  #smallScreen{
    display:block;
  }
}

